
import { AuthModule } from "@/store/modules/auth";
import { ElMessage } from "element-plus";
import { ErrorMessage, Field, Form, useField, useForm } from "vee-validate";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface PasswordConfirm {
  password: string;
  password2: string;
}

export default defineComponent({
  name: "password-confirmation",
  components: {
    Field,
    Form,
    ErrorMessage,
  },

  setup() {
    const { t, te } = useI18n();
    const i18n = (text) => (te(text) ? t(text) : text);

    const store = useStore();

    const router = useRouter();
    const route = useRoute();
    const token = route.params.token;

    const authModule = getModule(AuthModule, store);

    const submitButton = ref<HTMLButtonElement | null>(null);

    const handleTokenError = async () => {
      ElMessage.error("El enlace no es válido o ha caducado");

      await router.push({ name: "acceso-familias" });
    };

    onMounted(async () => {
      let payload = {
        resetKey: token,
      };
      authModule.validateResetPasswordToken(payload).catch(() => {
        handleTokenError();
      });
    });

    //Create form validation object
    const passwordConfirm = Yup.object().shape({
      password: Yup.string()
        .required()
        .label(i18n("authSigninPasswordLabel"))
        .matches(
          /^(?=.*[\\w)(?=.*[0-9])(?=.{8,})/,
          i18n("authSigninPasswordlength")
        ),
      password2: Yup.string()
        .required()
        .label(i18n("authConfirmationPasswordPassword2Label"))
        .matches(
          /^(?=.*[\\w)(?=.*[0-9])(?=.{8,})/,
          i18n("auth.signin.passwordlength")
        )
        .oneOf(
          [Yup.ref("password"), null],
          i18n("authConfirmationPasswordDifferentPasswords")
        ),
    });

    useForm<PasswordConfirm>({
      validationSchema: passwordConfirm,
    });

    const { value: password } = useField("password");
    const { value: password2 } = useField("password2");

    //Form submit function
    const onSubmitLogin = () => {
      // Clear existing errors
      authModule.logout();

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value?.setAttribute("data-kt-indicator", "on");
      }

      // Send login request VER...................
      //{"resetKey": "KEY_PARA_RESETEAR","password": "NUEVA_PASS","confirmPassword": "NUEVA_PASS"}
      let payload = {
        resetKey: token,
        password: password.value,
        confirmPassword: password2.value,
      };

      authModule
        .changePassword(payload)
        .then(() => {
          // Ir a la pagina de login si cambia con exito la contraseña
          router.push({ name: "acceso-familias" });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          // Alert then changePassword failed
          ElMessage.error(store.getters.getErrors[error]);
        });
      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      passwordConfirm,
      submitButton,
      password,
      password2,
    };
  },
});
